import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modalContainer"];

  connect() {
    if (!this.hasModalContainerTarget) return;
    this.modal = new Modal(this.modalContainerTarget);

    this.modalContainerTarget.addEventListener(
      "turbo:submit-end",
      (formResponse) => {
        this.#closeModalAfterFormSubmission(formResponse);
      },
    );
  }

  open() {
    this.modal?.show();
  }

  close() {
    this.modal?.hide();
  }

  closeWithEsc(keyPressEvent) {
    if (keyPressEvent.keyCode === 27) this.close();
  }

  #closeModalAfterFormSubmission(formResponse) {
    // Keep the modal open if the response is HTTP 202 (accepted) status.
    //
    // We may use this response code if we want to update the content of
    // the modal with a new frame from the server.
    if (formResponse.detail.fetchResponse.response.status === 202) return;

    // Otherwise, if the response is successful then close the modal.
    if (event.detail.success) this.close();
  }
}
