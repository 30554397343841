class Camera {
  static async getCurrentPermission() {
    const result = await navigator.permissions.query({ name: "camera" });
    console.log("Camera permission status:", result.state);
    return result.state;
  }

  static async requestPermission(onPermit, onDeny) {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      onPermit();
    } catch (err) {
      console.error(err);
      onDeny();
    }
  }
}

export default Camera;
