import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";

import {
  jpCalculateTaxFromTotal,
  convertToDecimalRate,
} from "../../shared/tax_calculator";

// This controller is used for the JP line items editing tool.
export default class extends Controller {
  static targets = [
    "alcoholInput",
    "alcoholInputs",
    "destroyMarker",
    "discountValue",
    "isAlcohol",
    "isConsumable",
    "category",
    "categoryName",
    "quantity",
    "reducedTaxRate",
    "required",
    "tax",
    "taxRatePercentage",
    "toggleAlcoholIcon",
    "toggleConsumableIcon",
    "toggleDiscount",
    "toggleReducedTaxRateIcon",
    "toggleTaxIncludedInTotalIcon",
    "taxIncludedInTotal",
    "totalPrice",
    "unitPrice",
    "destroyButton",
  ];

  initialize() {
    this.reducedTaxRateTarget.addEventListener(
      "change",
      this.handleReducedTaxRateChange.bind(this),
    );

    this.isConsumableTarget.addEventListener(
      "change",
      this.handleIsConsumableChange.bind(this),
    );

    this.taxIncludedInTotalTarget.addEventListener(
      "change",
      this.handleToggleTaxIncludedInTotalChange.bind(this),
    );

    if (this.hasIsAlcoholTarget) {
      this.isAlcoholTarget.addEventListener(
        "change",
        this.handleIsAlcoholChange.bind(this),
      );
    }

    // Allows for small adjustments directly to the tax field
    this.taxTarget.addEventListener(
      "keyup",
      function () {
        this.dispatch("lineItemUpdated");
      }.bind(this),
    );
  }

  connect() {
    // Use MutationObserver (via useMutaion wrapper from stimulus-use) to watch
    // for changes in the hidden input. This is necessary because hidden inputs
    // don't trigger "change" events normally.
    useMutation(this, {
      element: this.destroyMarkerTarget,
      attributeFilter: ["value"],
    });
  }

  handleReducedTaxRateChange(event) {
    if (event.target.checked) {
      this.setReducedTaxRate();
    } else {
      this.setStandardTaxRate();
    }

    this.update();
  }

  setReducedTaxRate() {
    this.taxRatePercentageTarget.value = "8";
    this.toggleReducedTaxRateIconTarget.classList.add("pressed");
  }

  setStandardTaxRate() {
    this.taxRatePercentageTarget.value = "10";
    this.toggleReducedTaxRateIconTarget.classList.remove("pressed");
  }

  handleIsConsumableChange(event) {
    if (event.target.checked) {
      this.toggleConsumableIconTarget.classList.add("pressed");
    } else {
      this.toggleConsumableIconTarget.classList.remove("pressed");
    }

    this.dispatch("lineItemUpdated");
  }

  handleToggleTaxIncludedInTotalChange(event) {
    if (event.target.checked) {
      this.toggleTaxIncludedInTotalIconTarget.classList.add("pressed");
    } else {
      this.toggleTaxIncludedInTotalIconTarget.classList.remove("pressed");
    }

    this.update();
  }

  handleIsAlcoholChange(event) {
    if (event.target.checked) {
      this.enableAlcoholInputs();
      this.toggleAlcoholIconTarget.classList.add("pressed");
    } else {
      this.disableAlcoholInputs();
      this.toggleAlcoholIconTarget.classList.remove("pressed");
    }
  }

  adjustTaxUpOne(_event) {
    this.taxTarget.value = parseInt(this.taxTarget.value) + 1;
    this.dispatch("lineItemUpdated");
  }

  adjustTaxDownOne(_event) {
    this.taxTarget.value = parseInt(this.taxTarget.value) - 1;
    this.dispatch("lineItemUpdated");
  }

  mutate(_) {
    // This currenltly only used to recalculate summary when a line item is
    // marked for destruction.
    this.dispatch("lineItemUpdated");
  }

  get isStandardTaxRate() {
    return this.taxRatePercentageTarget.value === "10";
  }

  get isReducedTaxRate() {
    return this.taxRatePercentageTarget.value === "8";
  }

  get isConsumable() {
    return this.isConsumableTarget.checked;
  }

  get taxIncludedInTotal() {
    return this.taxIncludedInTotalTarget.checked;
  }

  get isAlcohol() {
    return this.isAlcoholTarget.checked;
  }

  get quantity() {
    return Number(
      this.quantityTarget.dataset.value || this.quantityTarget.value,
    );
  }

  get unitPrice() {
    return Number(
      this.unitPriceTarget.dataset.value || this.unitPriceTarget.value,
    );
  }

  get totalPrice() {
    return this.totalPriceTarget.value;
  }

  get taxRate() {
    return convertToDecimalRate(this.taxRatePercentageTarget.value);
  }

  get tax() {
    return this.taxTarget.value;
  }

  update() {
    this.totalPriceTarget.value = this.reCalculateTotal();
    this.taxTarget.value = this.reCalculateTaxFromTotal();

    this.dispatch("lineItemUpdated");
  }

  reCalculateTotal() {
    return this.quantity * this.unitPrice;
  }

  reCalculateTaxFromTotal() {
    return jpCalculateTaxFromTotal(
      this.totalPrice,
      this.taxRate,
      this.taxIncludedInTotal,
    );
  }

  markedForDestruction() {
    return this.destroyMarkerTarget.getAttribute("value") === "1";
  }

  enableAlcoholInputs() {
    this.alcoholInputsTarget.classList.remove("d-none");
    this.alcoholInputTargets.forEach((element) => {
      element.disabled = false;
      element.required = true;
    });
  }

  disableAlcoholInputs() {
    this.alcoholInputsTarget.classList.add("d-none");
    this.alcoholInputTargets.forEach((element) => {
      element.disabled = true;
      element.required = false;
    });
  }

  get totalPriceExclTax() {
    if (this.taxIncludedInTotal) {
      return this.totalPrice - this.tax;
    } else {
      return this.totalPrice;
    }
  }
}
