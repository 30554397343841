import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image"];

  connect() {
    console.log("multi-image-input controller connected");
  }

  imageTargetConnected(_) {
    if (this.imageTargets.length > 1) {
      this.imageTargets.forEach((target) => {
        target
          .getElementsByClassName("remove-image")[0]
          .classList.remove("d-none");
      });
    }
  }

  imageTargetDisconnected(_) {
    if (this.imageTargets.length < 2) {
      this.imageTargets.forEach((target) => {
        target
          .getElementsByClassName("remove-image")[0]
          .classList.add("d-none");
      });
    }
  }
}
