import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";
import Compressor from "compressorjs";

export default class extends Controller {
  static targets = ["verifiedBadge"];
  static values = {
    ocrEnabled: Boolean,
  };

  async handleFileSelected(event) {
    if (!this.ocrEnabledValue) {
      return;
    }

    const { form } = event.detail;
    const formUrl = form.action;
    const formData = new FormData(form);
    const csrfToken = document.querySelector(
      'meta[name="csrf-token"]',
    )?.content;

    this.dispatch("openModal");

    this.abortController = new AbortController();

    // minify image
    const file = formData.get("proof[image]");
    if (file && file.type.startsWith("image/")) {
      const compressedImage = await this.compressImage(file);
      // need to provide file name, make sure it's jpeg
      const jpegName = file.name.replace(/\.[^/.]+$/, ".jpg");
      formData.set("proof[image]", compressedImage, jpegName);
    }

    formData.set("proof[landing_date]", null);
    formData.set("proof[residence_status]", null);

    formData.set("skip_submit", "true");

    fetch(formUrl, {
      method: "PUT",
      body: formData,
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
      },
      signal: this.abortController.signal,
    })
      .then((response) => {
        if (!response.ok) throw new Error("Upload failed");
        return response.json();
      })
      .then((data) => {
        if (data.image_id) {
          this.triggerOcr(data.image_id, csrfToken, form);
        }
      })
      .catch((error) => {
        console.error("Upload failed:", error);
        this.dispatch("closeModal");
      });
  }

  handleOcrCancel() {
    this.abortController.abort("User aborted");
  }

  triggerOcr(imageId, csrfToken, form) {
    this.dispatch("showScreen", { detail: { name: "processing" } });

    fetch(`/images/${imageId}/jp_landing_permit`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
      },
      signal: this.abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          const error = data.data?.error_reason || data.error;
          this.dispatch("showError", { detail: { message: error } });
          this.markValid(false);
        } else {
          this.dispatch("closeModal");
          form.elements["proof[landing_date]"].value = data.data.landing_date;
          form.elements["proof[residence_status]"].value = data.data.status;
          this.markValid();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        this.dispatch("closeModal");
        this.markValid(false);
      });
  }

  markValid(valid = true) {
    if (valid) {
      this.verifiedBadgeTarget.classList.remove("d-none");
      this.verifiedBadgeTarget.classList.add("d-block");
    } else {
      this.verifiedBadgeTarget.classList.add("d-none");
      this.verifiedBadgeTarget.classList.remove("d-block");
    }
  }

  async compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        maxWidth: 3000,
        quality: 0.35,
        mimeType: "image/jpeg",
        convertTypes: ["image/png", "image/webp"],
        success: resolve,
        error: reject,
      });
    });
  }
}
