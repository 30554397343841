import { Controller } from "@hotwired/stimulus";
import { pieApiFetch } from "shared/fetch_decorators";
import I18n from "i18n-js";

/*
 * The product image is the only proof that requires geo-location.
 * This class will ask the browser for geo-location permission, and
 * show an error if it's not available on the device. If geo-location is blocked
 * then a button is shown that will reload the page and prompt the user again.
 *
 */
export default class extends Controller {
  static targets = [
    "geoAlert",
    "geoNotice",
    "geoLocationButton",
    "geoLocationInput",
    "imageInput",
  ];

  static values = {
    orderId: String,
  };

  connect() {
    console.info("product_image_form_controller_connect");

    this.#geoLocationSetup(
      this.#successfullyAccessedGeoLocation.bind(this),
      this.#handleGeoLocationError.bind(this),
    );
  }

  reloadPage(event) {
    event.preventDefault();
    window.location.reload(true);
  }

  async #successfullyAccessedGeoLocation(latitude, longitude) {
    console.info("successfullyAccessedGeoLocation");

    const is_refundable_to_location = await this.#is_refundable_to_location(
      latitude,
      longitude,
    );

    if (is_refundable_to_location) {
      this.geoLocationInputTarget.value = `${latitude},${longitude}`;
      this.geoNoticeTarget.style.display = "none";
      this.geoAlertTarget.style.display = "none";
      this.geoLocationButtonTarget.style.display = "none";
      this.imageInputTarget.style.display = "block";
    } else {
      this.#handleGeoLocationError(
        I18n.t("global.summary.at_home.modal_description"),
        false,
      );
    }
  }

  async #is_refundable_to_location(latitude, longitude) {
    const response = await pieApiFetch(
      `/api/orders/${this.orderIdValue}/is_refundable_to_location`,
      { body: { lat: latitude, lon: longitude } },
    );

    const responseData = await response.json();
    return responseData.is_refundable_to_location;
  }

  #handleGeoLocationError(errorMsg, displayGeoLocationButton = true) {
    console.log(errorMsg);

    this.geoAlertTarget.innerHTML = errorMsg;
    this.geoAlertTarget.style.display = "block";
    this.geoNoticeTarget.style.display = "none";
    if (displayGeoLocationButton)
      this.geoLocationButtonTarget.style.display = "block";
  }

  #geoLocationSetup(onGetCurrentPosition, onError) {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          onGetCurrentPosition(
            position.coords.latitude,
            position.coords.longitude,
          );
        },
        function (error) {
          onError(error.message);
        },
      );
    } else {
      onError(
        "Can not fetch location on device, please try another device or browser.",
      );
    }
  }
}
