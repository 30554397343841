import { Controller } from "@hotwired/stimulus";

import { checkResponseOk, pieFormFetch } from "../shared/fetch_decorators";

export default class extends Controller {
  static values = {
    showPreview: Boolean,
    triggerFileInputClick: Boolean,
    imageId: String,
  };
  static targets = ["errorMessage", "imagePreview", "fileInput"];

  fileInputTargetConnected(target) {
    // Promise.resolve() pushes to the next tick after connection completes.
    Promise.resolve().then(() => {
      target.disabled = false;
      this.dispatch("ready");

      if (this.triggerFileInputClickValue) {
        this.fileInputTarget.click();
      }
    });
  }

  fileAdded() {
    const reader = new FileReader();
    reader.onloadend = async (e) => {
      this.imagePreviewTarget.src = e.target.result;
      this.imagePreviewTarget.style.display = "block";

      if (!file) {
        return;
      }

      if (this.imageIdValue.length > 0) {
        // only do an instant upload, if we are retaking
        // a persisted picture
        await this.retake(file);
        this.fileInputTarget.value = "";
        //} else {
        //await this.uploadNew(file);
      }
      //this.fileInputTarget.value = "";
    };

    const file = this.fileInputTarget.files && this.fileInputTarget.files[0];
    if (this.validateFileSize(file)) {
      reader.readAsDataURL(file);
    } else {
      this.fileInputTarget.value = "";
    }
  }

  show() {
    this.element.classList.replace("d-none", "d-flex");
  }

  validateFileSize(file) {
    const size = file?.size;
    if (size && size / 1024 > 10000) {
      this.errorMessageTarget.style.display = "block";
      this.imagePreviewTarget.style.display = "none";
      return false;
    } else {
      this.errorMessageTarget.style.display = "none";
      return true;
    }
  }

  async retake(file) {
    const formData = new FormData();
    formData.append("file", file);

    const url = `/images/${this.imageIdValue}/retake`;
    const response = await pieFormFetch(url, {
      method: "PUT",
      body: formData,
      headers: { Accept: "application/json" },
    });

    await checkResponseOk(response, "Failed to update image");

    // Update image ID
    const updatedImage = await response.json();
    this.imageIdValue = updatedImage.id;
  }

  async uploadNew(file) {
    const form = this.element.closest("form");
    if (!form) return;

    const formData = new FormData();
    formData.append("proof[images][]", file);
    formData.append("skip_submit", true);

    const response = await pieFormFetch(form.action, {
      method: "PUT",
      body: formData,
      headers: { Accept: "application/json" },
    });

    await checkResponseOk(response, "Failed to upload image");
  }

  async remove() {
    // only remove an uploaded image
    if (this.imageIdValue.length <= 0) {
      return;
    }
    const form = this.element.closest("form");
    if (!form || !this.hasImageIdValue) return;

    const url = `/images/${this.imageIdValue}`;

    const response = await pieFormFetch(url, {
      method: "DELETE",
      headers: { Accept: "application/json" },
    });
    await checkResponseOk(response, "Failed to delete image");

    this.imagePreviewTarget.src = "";
    this.imagePreviewTarget.style.display = "none";
    this.fileInputTarget.value = "";
    this.element.remove();
  }
}
