import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["screen", "cancelButton", "errorMessage"];

  connect() {
    this.modal = new Modal(this.element);
  }

  show() {
    this.modal.show();
    this.showScreen({ detail: { name: "uploading" } });
    this.showCancelButtonAfterDelay();
  }

  hide() {
    this.modal.hide();
  }

  showScreen({ detail: { name } }) {
    this.screenTargets.forEach((screen) => {
      screen.classList.add("d-none");
    });

    const screen = this.screenTargets.find(
      (target) => target.dataset.screen === name,
    );

    if (screen) {
      screen.classList.remove("d-none");
    }
  }

  showError({ detail: { message } }) {
    this.showScreen({ detail: { name: "error" } });
    this.showCancelButton();
    this.errorMessageTarget.textContent = message;
  }

  handleCancel() {
    this.dispatch("ocrCancel");
    this.hide();
  }

  showCancelButton() {
    this.cancelButtonTarget.classList.remove("d-none");
  }

  showCancelButtonAfterDelay() {
    setTimeout(() => this.showCancelButton(), 7000);
  }
}
