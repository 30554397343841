<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <div class="card">
    <PopOver
      v-if="displayPopup"
      :close-icon-click-action="hidePopup"
      :list-item-click-action="selectResource"
      :list-items="available_resources"
      :list-item-display-attribute="resource_display"
      :title="`Select ${resource_label}`"
      :is-loading="$store.getters.isLoading()"
    />
    <div class="card-header">
      {{ card_label }}
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12">
          <div v-if="error.length > 0" class="alert alert-danger">
            {{ error }}
          </div>

          <div class="form-group col-sm-12">
            <label>Search</label>
            <input
              v-model="resourceSearch"
              class="form-control"
              type="text"
              @input="searchResource"
              @focus="showPopup()"
              @keyup.enter="searchResource()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// TODO
/* eslint-disable vue/prop-name-casing */
import PopOver from "components/popover.vue";

export default {
  components: {
    PopOver,
  },
  props: [
    "available_resources",
    "resource_label",
    "card_label",
    "searchQuery",
    "assignmentDispatch",
    "resource_display",
    "error",
  ],
  data() {
    return {
      resourceSearch: "",
      displayPopup: false,
      debouncing: false,
      last_debounced: 0,
    };
  },
  methods: {
    searchResource(_event) {
      this.last_debounced = Date.now();
      if (!this.debouncing) {
        this.debouncing = true;
        setTimeout(
          function () {
            const updated_diff = Date.now() - this.last_debounced;
            if (updated_diff >= 300 && this.resourceSearch.trim().length > 2) {
              this.$store.dispatch(
                this.searchQuery,
                this.resourceSearch.trim(),
              );
              this.debouncing = false;
            } else {
              this.debouncing = false;
              this.searchResource();
            }
          }.bind(this),
          300,
        );
      }
    },
    selectResource(resource) {
      if (resource.id !== null && typeof resource.id !== "undefined") {
        this.hidePopup();
        this.$store.dispatch(this.assignmentDispatch, resource.id);
      }
    },
    showPopup() {
      this.displayPopup = true;
    },
    hidePopup() {
      this.displayPopup = false;
    },
  },
};
</script>
<style scoped>
.tag__list {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.order__tag {
  background-color: #dfe3e8;
  color: #454f5b;
  display: inline-flex;
  word-wrap: break-word;
  max-width: 100%;
  min-height: 24px;
  border-radius: 3px;
  padding: 0px 5px 0px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.order__tag__label {
  flex: 0 1 auto;
  min-width: 0;
  width: 100%;
  margin-right: 5px;
  display: block;
}
.order__tag__remove {
  flex-shrink: 0;
  display: block;
  cursor: pointer;
  float: right;
}

.popover.popover__selector {
  top: 140px;
}
</style>
